export enum DOCTYPE {
  CompanyRegistrationDocument = 22,
  LetterOfAuthorization = 1,
  OtherRelevantDocuments = 13,
}

export enum SERVICE_TYPE {
  TemplateCompanyRegistration = 7,
  TemplateLetterOfAuthorization = 8,
}
